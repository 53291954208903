import React, { createContext, useState, useContext, useEffect } from "react";

type Theme = "light" | "dark";
type ThemeContextType = {
  theme: Theme;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(() => {
    if (typeof window !== "undefined") {
      // Check for URL parameter and use it if available
      const params = new URLSearchParams(window.location.search);
      const urlTheme = params.get("theme");
      if (urlTheme === "dark" || urlTheme === "light") {
        // Update the URL to remove the theme parameter
        params.delete("theme");
        // Check if there are any parameters left
        const newQueryString = params.toString();
        const newUrl = newQueryString
          ? `${window.location.pathname}?${newQueryString}`
          : window.location.pathname;
        window.history.replaceState({}, "", newUrl);
        return urlTheme;
      }
    }
    // Then check for a saved theme in local storage
    const localTheme =
      typeof window !== "undefined" ? localStorage.getItem("theme") : null;
    if (localTheme === "dark" || localTheme === "light") {
      return localTheme as Theme;
    }

    // Finally, default to the browser's theme preference
    if (typeof window !== "undefined" && window.matchMedia) {
      const prefersDarkMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      return prefersDarkMode ? "dark" : "light";
    }

    // If all else fails, default to 'light'
    return "light";
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("theme", theme);
      document.documentElement.className = theme;
      document.documentElement.style.colorScheme = theme;
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
